import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Typography,
  FormGroup,
  CircularProgress,
  Grid,
  Checkbox,
  FormControlLabel,
  useTheme,
  Box,
  Button,
} from '@mui/material';
import { PaylineWidget } from 'react-payline';
import { useBroadcast } from '../../../socket.io-react';
import PaylineWalletLayout from './PaylineWalletLayout';
import { saveEntity, fetchEntity } from '../../../reducers/entities';
import { saveState } from '../../../reducers/payline';
import { StyledPaylineWidgetWrapper } from './Payline.style';

const PaylineWalletScreen = ({ deviceId, paylineState, token, clubName, walletId, dispatch }) => {
  const theme = useTheme();
  const intl = useIntl();
  const broadcast = useBroadcast();
  const [useInRejects, setUseInRejects] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isWallet, setIsWallet] = useState(false);

  /** @type {import('react-payline/src/PaylineWidget').FinalStateHasBeenReachedHandler} */

  const onShowFinalState = useCallback(
    data => {
      dispatch(saveState(data.state));
      broadcast(`device-${deviceId}.message`, {
        type: 'payline_wallet_final_state',
        payload: data.state,
      });
    },
    [broadcast, deviceId, dispatch]
  );

  const onCheckboxChange = async event => {
    setUseInRejects(event.target.checked);
    await dispatch(saveEntity(walletId, { data: { useInRejects: event.target.checked } }));
  };

  useEffect(() => {
    const getWallet = async () => {
      const { result } = await dispatch(fetchEntity(walletId));
      setUseInRejects(result.useInRejects);
      setIsLoading(false);
    };
    getWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDidShowState = useCallback(data => {
    if (data.state === 'MANAGE_WEB_WALLET') setIsWallet(true);
  }, []);

  return (
    <PaylineWalletLayout>
      <Grid item xs={12}>
        <Typography variant="h5" align="center" style={{ marginTop: 0 }}>
          <FormattedMessage id="payline.wallet.title" />
        </Typography>
      </Grid>

      {!token || isLoading ? (
        <Grid container alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
          <Grid item>
            <CircularProgress size={60} />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <StyledPaylineWidgetWrapper>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={useInRejects}
                onChange={onCheckboxChange}
                label={intl.formatMessage(
                  {
                    id: 'payline.wallet.useInRejects',
                  },
                  {
                    clubName: clubName || '-',
                  }
                )}
              />
            </FormGroup>
            <PaylineWidget
              token={token}
              template="lightbox"
              embeddedRedirectionAllowed
              onFinalStateHasBeenReached={onShowFinalState}
              onDidShowState={handleOnDidShowState}
            />
          </StyledPaylineWidgetWrapper>
          {isWallet && (
            <Box
              sx={{
                position: "fixed",
                bottom: theme.spacing(3),
                right: theme.spacing(3)
              }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => onShowFinalState({ state: 'FINAL_STATE' })}
              >
                {intl.formatMessage({ id: 'payline.wallet.end' })}
              </Button>
            </Box>
          )}
        </Grid>
      )}
    </PaylineWalletLayout>
  );
};

PaylineWalletScreen.propTypes = {
  deviceId: PropTypes.string.isRequired,
  paylineState: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  walletId: PropTypes.string.isRequired,
  clubName: PropTypes.string.isRequired,
};

export default PaylineWalletScreen;
