import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import { useIntl } from 'react-intl';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { change } from '../../reducers/locale';
import { fetchEntityList } from '../../reducers/entities';
import { LANGUAGES_LIST } from '../../endpoints/intlApi';
import { denormalizeEntityList } from '../../endpoints';

const LanguageSelector = ({ locale, dispatch, languages }) => {
  const intl = useIntl();
  const options = languages.map(lang => ({ id: lang.locale, label: lang.name }));

  return (
    <div style={{ position: 'absolute', top: 10, right: 10, minWidth: 250 }}>
      <FormControl fullWidth>
        <InputLabel>{intl.formatMessage({ id: 'startup.language-selector' })}</InputLabel>
        <Select value={locale} onChange={event => dispatch(change(event.target.value))}>
          {options.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

LanguageSelector.propTypes = {
  dispatch: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state, { languageResult }) => ({
  locale: state.locale.current,
  languages: denormalizeEntityList(languageResult, state.entities),
});

export default compose(
  withState('languageResult', 'setLanguageResult', []),
  connect(mapStateToProps),
  lifecycle({
    async componentDidMount() {
      const { dispatch, setLanguageResult } = this.props;
      const { result } = await dispatch(fetchEntityList(LANGUAGES_LIST));
      setLanguageResult(result);
    },
  })
)(LanguageSelector);
