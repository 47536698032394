import { createSelector } from '@reduxjs/toolkit';
import { denormalizeEntity, denormalizeEntityList } from '../endpoints';
import { getEntities } from './entities';
import { getClientScreenProps } from './clientScreen';

export const getSaleContract = state => state.saleContract;

export const getSaleId = createSelector(
  [getClientScreenProps],
  (clientScreenProps = {}) => clientScreenProps.saleId
);

export const getSale = createSelector(
  [getSaleId, getEntities],
  (saleId, entities) => entities.sales?.[saleId]
);

export const getClubId = createSelector([getSale], sale => sale && sale.clubId);

export const getClub = createSelector(
  [getClubId, getEntities],
  (clubId, entities) => entities.clubs?.[clubId]
);

export const getSaleContact = createSelector([getSale], sale => sale && sale.contactId);

export const getContactId = createSelector(
  [getClientScreenProps],
  (clientScreenProps = {}) => clientScreenProps.contactId
);

export const getContactOptins = createSelector(
  [getSaleContact, getEntities],
  (contactId, entities) => denormalizeEntity(contactId, entities)
);

export const getArticleIds = createSelector(
  [getSaleContract],
  saleContract => saleContract.articleIds
);

export const getSteps = createSelector([getSaleContract, getClub], (saleContract, club) => {
  if (club?.contactAutomaticOptin) {
    return saleContract.steps.filter(step => step !== 'sale-contract.steps-choose-communication');
  }
  return saleContract.steps;
});

/* OPTINS */

export const getOptinIds = createSelector([getSaleContract], saleContract => saleContract.optinIds);

export const getOptins = createSelector([getOptinIds, getEntities], (optinIds, entities) =>
  denormalizeEntityList(optinIds, entities)
);

export const getGroupedOptins = createSelector([getOptins], optins => {
  const result = optins.reduce((acc, optin) => {
    if (!acc[optin.type]) acc[optin.type] = [];
    acc[optin.type].push(optin);
    return acc;
  }, {});
  return result;
});

export const getOptinStatus = createSelector([getOptins], optins => {
  const status = {};
  if (!optins) return status;
  optins.forEach(optin => {
    if (status[optin.type] === undefined) status[optin.type] = optin.isAuthorized;
    if (status[optin.type] !== optin.isAuthorized) status[optin.type] = 'mixed';
  });
  return status;
});

export const getArticles = createSelector([getArticleIds, getEntities], (articleIds, entities) =>
  denormalizeEntityList(articleIds, entities)
);

const isRecurrent = article => {
  const { occurrences = null, recurrences = null } = article.repaymentSchedule;
  const occurrencesSize = occurrences ? occurrences.length : 0;
  const recurrencesSize = recurrences ? recurrences.length : 0;

  return occurrencesSize + recurrencesSize !== 0;
};

export const getNeedMandate = createSelector([getArticles], articles =>
  articles.some(article => isRecurrent(article))
);

export const getValidTerms = createSelector(
  [getSaleContract, getNeedMandate],
  ({ acceptTerms, acceptMandate }, needMandate) =>
    acceptTerms && (needMandate ? acceptMandate : true)
);
