import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { getEntity } from '../../../selectors/entities';
import {
  setStep as setStepAction,
  setArticleIds as setArticleIdsAction,
  reset,
} from '../../../reducers/saleContract';
import { fetchEntity, fetchEntityList } from '../../../reducers/entities';
import { getSaleContract, getSteps } from '../../../selectors/saleContract';
import { ARTICLES_LIST } from '../../../endpoints/saleApi';
import SaleContract from './SaleContract';

const mapStateToProps = (state, { saleId }) => ({
  ...getSaleContract(state),
  sale: getEntity(state, { collection: 'sales', entityId: saleId }),
  steps: getSteps(state),
});

const actionCreators = {
  setActiveStep: setStepAction,
  setArticleIds: setArticleIdsAction,
};

export default compose(
  connect(),
  connect(mapStateToProps, actionCreators),
  lifecycle({
    async componentDidMount() {
      const { saleId, dispatch, setArticleIds } = this.props;
      const saleData = await dispatch(fetchEntity(saleId));
      await dispatch(fetchEntity(saleData.response.data.clubId));
      const data = await dispatch(
        fetchEntityList(ARTICLES_LIST, {
          params: {
            sale: saleId,
          },
        })
      );
      setArticleIds(data.result);
    },

    componentDidUpdate(prevProps) {
      const { saleId, dispatch } = this.props;
      if (prevProps.saleId !== saleId) {
        dispatch(fetchEntity(saleId));
      }
    },

    componentWillUnmount() {
      this.props.dispatch(reset());
    },
  })
)(SaleContract);
