import { compose, lifecycle, withHandlers, withStateHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { getSaleContract, getOptinStatus } from '../../../../selectors/saleContract';
import {
  fetchOptins as fetchOptinsAction,
  changeOptin as changeOptinAction,
  setStep as setStepAction,
} from '../../../../reducers/saleContract';
import CommunicationStep from './CommunicationStep';

const mapStateToProps = state => ({
  ...getSaleContract(state),
  optinsBase: getOptinStatus(state),
});

const actionCreators = {
  fetchOptins: fetchOptinsAction,
  setStep: setStepAction,
  changeOptin: changeOptinAction,
};

export default compose(
  connect(
    mapStateToProps,
    actionCreators
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchOptins();
    },
  }),
  withStateHandlers(
    {
      changedOptins: {},
    },
    {
      onChangeOptin: ({ changedOptins }) => (type, value) => ({
        changedOptins: {
          ...changedOptins,
          [type]: value,
        },
      }),
    }
  ),
  withProps(({ changedOptins, optinsBase }) => ({
    optinStatus: {
      ...optinsBase,
      ...changedOptins,
    },
  })),
  withHandlers({
    onValidOptins: ({ changeOptin, changedOptins, setStep, activeStep }) => () => {
      Object.keys(changedOptins).map(type => changeOptin(type, changedOptins[type]));
      setStep(activeStep + 1);
    },
  })
)(CommunicationStep);
