import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDeviceId } from '../../selectors/config';
import Startup from './Startup';

const mapStateToProps = state => ({
  deviceId: getDeviceId(state),
});

export default compose(
  // container
  withRouter,
  connect(mapStateToProps)
)(Startup);
