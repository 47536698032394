import { createReducer } from '@reduxjs/toolkit';
import { fetchEntityList, saveEntity } from './entities';
import { OPTIN_LIST } from '../endpoints/communicationApi';
import { getSaleContact, getGroupedOptins, getContactId } from '../selectors/saleContract';

const initialState = {
  needMandate: false,
  activeStep: 0,
  acceptMandate: false,
  acceptTerms: false,
  articleIds: [],
  optinIds: [],
  steps: [
    'sale-contract.steps-validate-cgv',
    'sale-contract.steps-choose-communication',
    'sale-contract.steps-sign-form',
  ],
};

const SET_STEP = 'saleContract/SET_STEP';
const SET_NEED_MANDATE = 'saleContract/SET_NEED_MANDATE';
const SET_ACCEPT_TERMS = 'saleContract/SET_ACCEPT_TERMS';
const SET_ACCEPT_MANDATE = 'saleContract/SET_ACCEPT_MANDATE';
const SET_ARTICLE_IDS = 'saleContract/SET_ARTICLE_IDS';
const SET_OPTINS_IDS = 'saleContract/SET_OPTINS_ID';
const RESET = 'saleContract/RESET';
const FETCH_OPTINS = 'saleContract/FETCH_OPTINS';
const OPTINS_FETCHED = 'saleContract/OPTINS_FETCHED';
const CHANGE_OPTIN = 'saleContract/CHANGE_OPTIN';

// ------------------------------------
// Actions
// ------------------------------------

export const setStep = step => ({
  type: SET_STEP,
  step,
});

export const setArticleIds = ids => ({
  type: SET_ARTICLE_IDS,
  ids,
});

export const setOptinsIds = ids => ({
  type: SET_OPTINS_IDS,
  ids,
});

export const setNeedMandate = needMandate => ({
  type: SET_NEED_MANDATE,
  needMandate,
});

export const setAcceptTerms = accept => ({
  type: SET_ACCEPT_TERMS,
  accept,
});

export const setAcceptMandate = accept => ({
  type: SET_ACCEPT_MANDATE,
  accept,
});

export const reset = () => ({
  type: RESET,
});

export const fetchOptins = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_OPTINS });

  const contact = getSaleContact(getState());
  const { result } = await dispatch(
    fetchEntityList(OPTIN_LIST, {
      params: {
        contactId: contact,
        type: ['advertising', 'partners', 'legal', 'news'],
      },
    })
  );
  dispatch({
    type: OPTINS_FETCHED,
    result,
  });
};

export const changePictureAllowed = value => async (dispatch, getState) => {
  dispatch({ type: 'saleContract/CHANGE_PICTURE_ALLOWED' });
  const contactId = getContactId(getState());
  try {
    await dispatch(
      saveEntity(contactId, {
        data: {
          pictureAllowed: value,
        },
      })
    );

    dispatch({ type: 'saleContract/CHANGE_PICTURE_ALLOWED_SUCCESS' });
  } catch ({ response }) {
    dispatch({ type: 'saleContract/CHANGE_PICTURE_ALLOWED_ERROR', response });
  }
};

export const changeOptin = (optinType, optinValue) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_OPTIN });
  const groupedOptins = getGroupedOptins(getState());
  const optins = groupedOptins[optinType];

  try {
    await Promise.all(
      optins.map(optin =>
        dispatch(
          saveEntity(optin['@id'], {
            data: {
              isAuthorized: optinValue,
            },
          })
        )
      )
    );
    dispatch({ type: 'saleContract/CHANGE_OPTIN_SUCCESS' });
  } catch ({ response }) {
    dispatch({ type: 'saleContract/CHANGE_OPTIN_ERROR', response });
  }
};

// ------------------------------------
// Handlers
// ------------------------------------

const handleSetStep = (prevState, { step }) => ({
  ...prevState,
  activeStep: step,
});

const handleSetArticleIds = (prevState, { ids }) => ({
  ...prevState,
  articleIds: ids,
});

const handleSetOptinIds = (prevState, { ids }) => ({
  ...prevState,
  optinIds: ids,
});

const handleSetNeedMandate = (prevState, { needMandate }) => ({
  ...prevState,
  needMandate,
});

const handleAcceptMandate = (prevState, { accept }) => ({
  ...prevState,
  acceptMandate: accept,
});

const handleAcceptTerms = (prevState, { accept }) => ({
  ...prevState,
  acceptTerms: accept,
});

const handleOptinsFetched = (prevState, { result }) => ({
  ...prevState,
  optinIds: result,
});

const handleReset = () => initialState;

// ------------------------------------
// Reducer
// ------------------------------------

export default createReducer(initialState, {
  [SET_STEP]: handleSetStep,
  [SET_NEED_MANDATE]: handleSetNeedMandate,
  [SET_ACCEPT_MANDATE]: handleAcceptMandate,
  [SET_ACCEPT_TERMS]: handleAcceptTerms,
  [SET_ARTICLE_IDS]: handleSetArticleIds,
  [SET_OPTINS_IDS]: handleSetOptinIds,
  [OPTINS_FETCHED]: handleOptinsFetched,
  [RESET]: handleReset,
});
