import exposeSocketBroadcast from './exposeSocketBroadcast';
import registerSocketListener from './registerSocketListener';

const compose = (...fns) =>
  fns.reduceRight((prevFn, nextFn) => (...args) => nextFn(prevFn(...args)), value => value);

const connectSocket = (listeners = {}) => {
  const eventHandlers = Object.entries(listeners).map(([eventName, handler]) =>
    registerSocketListener(eventName, handler)
  );
  return compose(
    exposeSocketBroadcast,
    ...eventHandlers
  );
};

/** @deprecated */
export default connectSocket;
