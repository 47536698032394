import React from 'react';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';
import { SocketProvider } from './socket.io-react';
import { getSocketConfig, getDeviceId } from './selectors/config';

const withSocket = WrappedComponent => props => {
  const config = useSelector(state => getSocketConfig(state));
  const deviceId = useSelector(state => getDeviceId(state));

  const socket = io(config.baseUrl, {
    transports: ['websocket', 'polling'],
  });
  socket.on('connect', () =>
    socket.emit('client.describe', { room: config.room, type: 'resa2-desk-terminal', deviceId })
  );

  return (
    <SocketProvider socket={socket}>
      <WrappedComponent {...props} />
    </SocketProvider>
  );
};

export default withSocket;
