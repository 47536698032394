import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRouterBasename } from './selectors/config';

const mapStateToProps = state => ({
  basename: getRouterBasename(state),
});

const withRouter = WrappedComponent => {
  const WithRouter = ({ basename, ...props }) => (
    <Router basename={basename}>
      <WrappedComponent {...props} />
    </Router>
  );

  WithRouter.propTypes = {
    basename: PropTypes.string,
  };

  WithRouter.defaultProps = {
    basename: undefined,
  };

  return connect(mapStateToProps)(WithRouter);
};

export default withRouter;
