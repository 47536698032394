import axios from 'axios';
import paramsSerializer from './paramsSerializer';

const preventInvalidUrl = request => {
  if (request.url === request.baseURL) {
    return Promise.reject(
      new Error(
        `Invalid request URL (did you try to ${request.method.toUpperCase()} something "undefined"?)`
      )
    );
  }

  return request;
};

const preventPagination = request => {
  if (request.params && request.params.pagination !== undefined) {
    throw new Error('"pagination" parameter is now forbidden.');
  }

  return request;
};

const addDefaultContentType = request => ({
  ...request,
  headers: {
    'Content-Type': 'application/ld+json',
    ...request.headers,
  },
});

const makeClient = () => {
  // create axios client with custom params serializer
  const client = axios.create({
    paramsSerializer,
  });

  // attach request interceptors
  client.interceptors.request.use(preventInvalidUrl);
  client.interceptors.request.use(preventPagination);
  client.interceptors.request.use(addDefaultContentType);

  return client;
};

export default makeClient;
