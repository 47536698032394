import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  current: localStorage.getItem('locale') || 'fr',
};

// ------------------------------------
// Actions
// ------------------------------------

export const change = locale => ({
  type: 'locale/CHANGE',
  value: locale,
});

// ------------------------------------
// Handlers
// ------------------------------------

const handleChange = (state, action) => {
  localStorage.setItem('locale', action.value);
  return {
    ...state,
    current: action.value,
  };
};
// ------------------------------------
// Reducer
// ------------------------------------

export default createReducer(initialState, {
  'locale/CHANGE': handleChange,
});
