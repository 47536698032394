import makeApiClient from '../services/makeApiClient';
import { getApiConfig } from '../selectors/config';
import { getAuthenticationHeaders } from '../selectors/authentication';

const sendHTTPRequest = requestconfig => async (dispatch, getState) => {
  const apiConfig = getApiConfig(getState());
  const authenticationHeaders = getAuthenticationHeaders(getState());

  const configuration = {
    baseURL: apiConfig.baseUrl,
    ...requestconfig,
    headers: {
      ...authenticationHeaders,
      ...requestconfig.headers,
    },
  };

  // add an entry to the redux log, to ease debugging
  dispatch({ type: '@@HTTP_REQUEST', ...configuration });

  const client = makeApiClient();
  const httpResponse = await client.request(configuration);

  return httpResponse;
};

export default sendHTTPRequest;
