import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { startReportingRuntimeErrors, stopReportingRuntimeErrors } from 'react-error-overlay';

import { register as registerError, unregister as unregisterError } from './unhandledError';
import { register as registerPromise, unregister as unregisterPromise } from './unhandledRejection';

const FallbackComponent = () => (
  <h1>
    <FormattedMessage id="global.error" />
  </h1>
);

class ErrorBoundary extends React.Component {
  state = { hasError: false, wasReportingRuntimeErrors: false };

  componentDidMount() {
    const { global } = this.props;

    if (global) {
      try {
        stopReportingRuntimeErrors();
        this.setState(state => ({ ...state, wasReportingRuntimeErrors: true }));
      } catch (error) {
        // it's okay, i don't blame you :)
      }

      registerError(window, this.handleGlobalException);
      registerPromise(window, this.handleGlobalException);
    }
  }

  componentWillUnmount() {
    const { global } = this.props;
    const { wasReportingRuntimeErrors } = this.state;

    if (global) {
      if (wasReportingRuntimeErrors) {
        startReportingRuntimeErrors();
      }

      unregisterError(window);
      unregisterPromise(window);
    }
  }

  handleGlobalException = () => {
    // Display fallback UI
    this.setState({ hasError: true });
  };

  componentDidCatch() {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return <FallbackComponent />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  global: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ErrorBoundary.defaultProps = {
  global: false,
};

export default ErrorBoundary;
