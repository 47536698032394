import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Stepper, Step, StepButton } from '@mui/material';

const SaleContractNav = ({ activeStep, steps, setActiveStep }) => {
  const intl = useIntl();

  return (
    <Stepper style={{ width: '90%' }} activeStep={activeStep}>
      {steps.map((step, index) => (
        <Step key={step}>
          <StepButton color="primary" onClick={() => setActiveStep(index)}>
            {intl.formatMessage({ id: step })}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

SaleContractNav.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string),
  setActiveStep: PropTypes.func.isRequired,
};

export default SaleContractNav;
