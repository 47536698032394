import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getDeviceId } from '../../selectors/config';
import registerSocketListener from '../../socket.io-react/registerSocketListener';

const mapStateToProps = state => ({
  deviceId: getDeviceId(state),
});

// eslint-disable-next-line no-console
const handleMessage = () => ({ type, ...msg }) => console.log(`Received "${type}" message: `, msg);

export default compose(
  connect(
    mapStateToProps,
    null,
    null,
    { pure: false }
  ),
  registerSocketListener(props => `device-${props.deviceId}.message`, handleMessage)
);
