import React from 'react';
import PropTypes from 'prop-types';
import SaleContract from './SaleContract';
import MandateScreen from './MandateScreen';
import PaylineWalletScreen from './PaylineWalletScreen';
import StandbyScreen from './StandbyScreen';
import NoMatch from '../NoMatch';
import CancellationCertificate from './CancellationCertificate/CancellationCertificate';

const ClientScreen = ({ screen }) => {
  switch (screen.name) {
    case 'standby':
      return <StandbyScreen />;
    case 'contract-signature':
      return <SaleContract key={screen.id} {...screen.props} />;
    case 'mandate-signature':
      return <MandateScreen key={screen.id} {...screen.props} />;
    case 'payline_wallet':
      return <PaylineWalletScreen key={screen.id} {...screen.props} />;
    case 'certificate-signature':
      return <CancellationCertificate key={screen.id} {...screen.props} />;
    default:
      return <NoMatch />;
  }
};

ClientScreen.propTypes = {
  screen: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    props: PropTypes.object,
  }),
};

export default ClientScreen;
