import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, Typography, FormControlLabel, FormGroup, Checkbox, Button } from '@mui/material';

const CommunicationStep = ({ onValidOptins, optinStatus, onChangeOptin }) => (
  <Grid container spacing={1} direction="column" style={{ height: '100%', textAlign: 'center' }}>
    <Grid item>
      <Typography style={{ marginTop: 0 }} variant="h5">
        <FormattedMessage id="sale-contract.communication.title" />
      </Typography>
      <Typography variant="subtitle1">
        <FormattedMessage id="sale-contract.communication.subtitle" />
      </Typography>
    </Grid>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        flex: 1,
      }}
    >
      <Grid item>
        {Object.keys(optinStatus).map(
          optinType =>
            optinType !== 'legal' && (
              <FormGroup key={optinType} row>
                <FormControlLabel
                  checked={optinStatus[optinType]}
                  control={
                    <Checkbox color="primary" indeterminate={optinStatus[optinType] === 'mixed'} />
                  }
                  onChange={e => onChangeOptin(optinType, e.target.checked)}
                  label={<FormattedMessage id={`sale-contract.communication.optin.${optinType}`} />}
                  style={{ marginBottom: '20px', textAlign: 'left' }}
                />
              </FormGroup>
            )
        )}
      </Grid>
    </Grid>
    <Grid container wrap="wrap" spacing={1} justifyContent="flex-end">
      <Grid item>
        <Button color="primary" onClick={onValidOptins} variant="contained">
          <FormattedMessage id="global.continue" />
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

CommunicationStep.propTypes = {
  onValidOptins: PropTypes.func.isRequired,
  optinStatus: PropTypes.object.isRequired,
  onChangeOptin: PropTypes.func.isRequired,
};

export default CommunicationStep;
