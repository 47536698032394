import { createReducer } from '@reduxjs/toolkit';
import uuidv4 from 'uuid/v4';
import parseLocation from '../utils/parseLocation';
import { getGrootParams, getClientToken } from '../selectors/config';

const callApi = async url => {
  const response = await fetch(url);

  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }

  throw new Error(response.statusText);
};

const { baseUrl, basename, clientToken } = parseLocation(
  document.location.href,
  process.env.REACT_APP_BASEURL_PATTERN
);

const generateDeviceId = () => {
  let source = uuidv4().split('-')[0];

  const parts = [];
  while (source.length) {
    parts.push(source.substr(0, 3));
    source = source.substr(3);
  }

  return parts.join('-');
};

const getDeviceId = () => {
  const savedDeviceId = localStorage.getItem('deviceId');
  if (savedDeviceId) return savedDeviceId;

  const generatedDeviceId = generateDeviceId();
  localStorage.setItem('deviceId', generatedDeviceId);
  return generatedDeviceId;
};

const initialState = {
  clientToken,
  deviceId: getDeviceId(),
  router: {
    baseUrl,
    basename,
  },
  api: {
    baseUrl: process.env.REACT_APP_API_BASEURL,
  },
  socket: {
    baseUrl: process.env.REACT_APP_SOCKET_BASEURL,
    room: clientToken,
  },
  groot: {
    baseUrl: process.env.REACT_APP_GROOT_BASEURL,
    config: {},
    version: '1.0.0',
  },
};

export const triggerFetchGrootConfig = () => async (dispatch, getState) => {
  dispatch({ type: 'config/FETCH_GROOT' });

  const config = await getGrootParams(getState());
  const token = await getClientToken(getState());
  const url = `${config.baseUrl}/api/${token}/resa2-desk-terminal/config/default/${config.version}`;

  try {
    const profileConfig = await callApi(url);
    dispatch({
      type: 'config/FETCH_GROOT_SUCCESS',
      payload: {
        profileConfig,
      },
    });
  } catch (error) {
    // no problem
  }
};

export default createReducer(initialState, {
  'config/FETCH_GROOT': state => ({
    ...state,
    groot: {
      ...state.groot,
      config: { ...state.groot.config },
    },
  }),
  'config/FETCH_GROOT_SUCCESS': (state, { payload }) => ({
    ...state,
    groot: {
      ...state.groot,
      config: {
        ...state.groot.config,
        ...payload.profileConfig,
      },
    },
  }),
});
