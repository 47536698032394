import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Sketchfield from '@stadline/mui-sketch-field';
import { Button, Grid, Typography } from '@mui/material';

const SignatureStep = ({ onSketchClear, value, onChange, onConfirm, isWaiting }) => {
  const intl = useIntl();

  return (
    <Grid container spacing={1} direction="column" style={{ height: '100%' }}>
      <Grid item>
        <Typography variant="h5" align="center" style={{ marginTop: 0 }}>
          <FormattedMessage id="sale-contract.signature.title" />
        </Typography>
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Sketchfield
          value={value}
          placeholder={intl.formatMessage({ id: 'mandate.signature.sketchfield.placeholder' })}
          width="100%"
          height="350px"
          onChange={onChange}
        />
      </Grid>
      <Grid container wrap="wrap" spacing={1} justifyContent="flex-end">
        {value && (
          <Grid item>
            <Button color="primary" onClick={onSketchClear} disabled={isWaiting} variant="outlined">
              <FormattedMessage id="mandate.signature.delete-signature" />
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            color="primary"
            disabled={!value || isWaiting}
            onClick={onConfirm}
            variant="contained"
          >
            <FormattedMessage id="mandate.signature.confirm" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

SignatureStep.propTypes = {
  onSketchClear: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isWaiting: PropTypes.bool.isRequired,
};

export default SignatureStep;
