import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setStep as setStepAction } from '../../../../reducers/saleContract';
import { getSteps, getSaleContract } from '../../../../selectors/saleContract';
import SaleContractNav from './SaleContractNav';

const mapStateToProps = state => ({
  steps: getSteps(state),
  activeStep: getSaleContract(state).activeStep,
});

const actionCreators = {
  setActiveStep: setStepAction,
};

export default compose(
  connect(
    mapStateToProps,
    actionCreators
  )
)(SaleContractNav);
