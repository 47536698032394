import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withPayline } from 'react-payline';
import { getClientToken } from '../../selectors/config';
import Startup from '../../pages/Startup';
import ClientScreen from '../../pages/ClientScreen';
import NoMatch from '../../pages/NoMatch';

const App = ({ clientToken }) =>
  clientToken ? (
    <Switch>
      <Route exact path="/" component={Startup} />
      <Route path="/ready" component={ClientScreen} />
      <Route component={NoMatch} />
    </Switch>
  ) : (
    <NoMatch />
  );

App.propTypes = {
  clientToken: PropTypes.string,
};

App.defaultProps = {
  clientToken: undefined,
};

const mapStateToProps = state => ({
  clientToken: getClientToken(state),
});

const PAYLINE_ENV = process.env.REACT_APP_PAYLINE_ENVIRONMENT || 'HOMO';

export default compose(
  withRouter,
  connect(mapStateToProps),
  withPayline(PAYLINE_ENV === 'PROD')
)(App);
