import { createElement, useRef, useMemo, useCallback } from 'react';
import useSocketListener from './useSocketListener';

const registerSocketListener = (eventName, handler) => WrappedComponent => {
  function SocketEventConnector(props) {
    const propsRef = useRef();
    propsRef.current = props;

    const resolvedEventName = useMemo(
      () => (typeof eventName === 'function' ? eventName(props) : eventName),
      [props]
    );
    const staticHandler = useCallback((...data) => handler(propsRef.current)(...data), []);

    useSocketListener(resolvedEventName, staticHandler);

    return createElement(WrappedComponent, props);
  }

  return SocketEventConnector;
};

/** @deprecated */
export default registerSocketListener;
