import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getDeviceId } from '../../../selectors/config';
import { getPaylineState } from '../../../selectors/payline';
import { getAuthenticationHeaders } from '../../../selectors/authentication';

import PalyneWalletScreen from './PaylineWalletScreen';

const mapStateToProps = state => ({
  paylineState: getPaylineState(state),
  deviceId: getDeviceId(state),
  authenticationHeaders: getAuthenticationHeaders(state),
});

export default compose(connect(mapStateToProps, dispatch => ({ dispatch })))(PalyneWalletScreen);
