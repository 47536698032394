import { createSelector } from '@reduxjs/toolkit';

/**
 * Returns clientScreen data
 *
 * @param {Object} state
 */
export const getClientScreen = state => state.clientScreen;

export const getClientScreenProps = createSelector(
  [getClientScreen],
  clientScreen => clientScreen.props
);
