import { createElement } from 'react';
import useBroadcast from './useBroadcast';

const exposeSocketBroadcast = WrappedComponent => {
  function SocketBroadcaster(props) {
    const broadcast = useBroadcast();
    return createElement(WrappedComponent, { ...props, broadcast });
  }

  return SocketBroadcaster;
};

/** @deprecated */
export default exposeSocketBroadcast;
