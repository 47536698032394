import { createReducer } from '@reduxjs/toolkit';
import { fetchEntity, fetchFile } from './entities';
import { IDENTIFY_CUSTOMIZED_THEME } from '../endpoints/customThemeApi';

const initialState = {
  customThemeId: null,
  isLoading: true,
};

const parseJson = data => JSON.parse(data);
const addDefaultId = data => ({ ...data, '@id': data['@id'] || 'default' });

export const triggerFetchCustomTheme = () => async dispatch => {
  dispatch({ type: 'customTheme/FETCH_CUSTOM_THEME' });

  try {
    // --------------------------
    // fetch custom public theme
    // --------------------------
    const customThemeResponse = await dispatch(
      fetchEntity(IDENTIFY_CUSTOMIZED_THEME, {
        params: { context: 'desk-terminal' },
        validateStatus: status =>
          (status >= 200 && status < 300) || status === 403 || status === 409,
        transformResponse: [parseJson, addDefaultId],
      })
    );

    const {
      result: customThemeId,
      response: { data: customThemeData },
    } = customThemeResponse;

    // ------------------
    // fetch public files
    // ------------------
    const fileIds = Object.values(customThemeData.properties)
      .filter(property => property instanceof Object && property.fileId !== undefined)
      .map(property => property.fileId);

    try {
      await Promise.all(
        fileIds.map(fileId => dispatch(fetchFile(fileId.replace('/files/logos', '/public/logos'))))
      );
    } catch (error) {
      // skip
    }

    dispatch({
      type: 'customTheme/FETCH_CUSTOM_THEME_SUCCESS',
      payload: { customThemeId },
    });
  } catch (error) {
    dispatch({ type: 'customTheme/HANDLE_FETCH_CUSTOM_THEME_ERROR', error });
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default createReducer(initialState, {
  'customTheme/FETCH_CUSTOM_THEME': state => ({
    ...state,
    error: undefined,
  }),
  'customTheme/FETCH_CUSTOM_THEME_SUCCESS': (state, { payload }) => ({
    ...state,
    isLoading: false,
    customThemeId: payload.customThemeId,
  }),
  'customTheme/HANDLE_FETCH_CUSTOM_THEME_ERROR': (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error.toString(),
  }),
});
