import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import messages from './intl';
import { getGrootConfigTranslation } from './selectors/config';

const mapStateToProps = state => ({
  locale: state.locale.current,
  customMessages: getGrootConfigTranslation(state),
});

const withIntl = WrappedComponent => {
  const WithIntl = ({ locale, customMessages, ...props }) => (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={{ ...messages[locale], ...(customMessages && customMessages[locale]) }}
    >
      <WrappedComponent {...props} />
    </IntlProvider>
  );

  WithIntl.propTypes = {
    locale: PropTypes.string.isRequired,
    customMessages: PropTypes.object,
  };

  return connect(mapStateToProps)(WithIntl);
};

export default withIntl;
