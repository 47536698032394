import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PDF from 'react-pdf-js-infinite';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Box,
} from '@mui/material';
import { Remove, Add } from '@mui/icons-material';

const buttonStyle = {
  margin: '0 10px',
  fontSize: '36px',
};

const ZoomButtons = ({ onZoom, zoom, disabled }) => (
  <div style={{ position: 'absolute', right: '20px', bottom: '20px', zIndex: 2 }}>
    <Button
      disabled={disabled || zoom <= 0.5}
      style={buttonStyle}
      onClick={() => onZoom(zoom - 0.5)}
      variant="fab"
    >
      <Remove />
    </Button>
    <Button
      disabled={disabled || zoom >= 3}
      style={buttonStyle}
      onClick={() => onZoom(zoom + 0.5)}
      variant="fab"
    >
      <Add />
    </Button>
  </div>
);

ZoomButtons.propTypes = {
  zoom: PropTypes.number.isRequired,
  onZoom: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const CGVStep = ({
  file,
  contact,
  acceptTerms,
  onChangeAcceptTerms,
  acceptMandate,
  onChangeAcceptMandate,
  nextStep,
  needMandate,
  isButtonActive,
  club,
  zoom,
  setZoom,
  setIsLoading,
  isLoading,
  changePictureAllowed,
}) => {
  const intl = useIntl();
  const [pictureAllowed, setPictureAllowed] = useState(null);
  useEffect(() => {
    if (contact && contact.pictureAllowed !== undefined) setPictureAllowed(contact.pictureAllowed);
  }, [contact, setPictureAllowed]);

  const handlePictureAllowedChange = useCallback(async value => {
    try {
      await changePictureAllowed(value);
      setPictureAllowed(value);
    } catch {
      setPictureAllowed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isPicturePermissionRequired = club && club.picturePermissionRequired;
  const isDisabled =
    (isPicturePermissionRequired && pictureAllowed === null) ||
    pictureAllowed === undefined ||
    !isButtonActive;

  return (
    <Grid container spacing={1} direction="column" style={{ height: '100%' }}>
      <Grid item>
        <Typography style={{ marginTop: 0 }} variant="h5" align="center">
          <FormattedMessage id="sale-contract.cgv.title" />
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        style={{
          flex: 1,
          borderBottom: '1px solid #f7f6f6',
          borderTop: '1px solid #f7f6f6',
          position: 'relative',
          backgroundColor: '#fff',
        }}
      >
        {file && (
          <Fragment>
            <ZoomButtons zoom={zoom} disabled={isLoading} onZoom={setZoom} />
            <div style={{ overflow: 'auto', height: '100%', width: '100%', position: 'absolute' }}>
              <PDF
                file={file}
                key={zoom}
                scale={zoom}
                onDocumentComplete={() => setIsLoading(false)}
              />
            </div>
          </Fragment>
        )}
      </Grid>
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            checked={acceptTerms}
            onChange={onChangeAcceptTerms}
            label={intl.formatMessage({ id: 'sale-contract.cgv.accept-terms' })}
          />
        </FormGroup>

        {needMandate && (
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              checked={acceptMandate}
              onChange={onChangeAcceptMandate}
              label={intl.formatMessage(
                {
                  id: 'sale-contract.cgv.accept-mandate',
                },
                { organization: club ? club.name : '' }
              )}
            />
          </FormGroup>
        )}
        {isPicturePermissionRequired && (
          <Box
            sx={{
              py: 1
            }}>
            <Typography>
              <FormattedMessage id="sale-contract.pictureAllowed.helperText" />
            </Typography>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={pictureAllowed === true}
                onChange={() => handlePictureAllowedChange(true)}
                label={intl.formatMessage({ id: 'sale-contract.pictureAllowed.agree' })}
              />
              <Box
                sx={{
                  px: 2
                }}>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  checked={pictureAllowed === false}
                  onChange={() => handlePictureAllowedChange(false)}
                  label={intl.formatMessage({ id: 'sale-contract.pictureAllowed.disagree' })}
                />
              </Box>
            </FormGroup>
          </Box>
        )}
      </Grid>
      <Grid container wrap="wrap" spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button color="primary" disabled={isDisabled} onClick={nextStep} variant="contained">
            <FormattedMessage id="global.continue" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

CGVStep.propTypes = {
  file: PropTypes.string,
  acceptTerms: PropTypes.bool.isRequired,
  onChangeAcceptTerms: PropTypes.func.isRequired,
  acceptMandate: PropTypes.bool.isRequired,
  onChangeAcceptMandate: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  needMandate: PropTypes.bool.isRequired,
  isButtonActive: PropTypes.bool.isRequired,
  club: PropTypes.shape({
    name: PropTypes.string,
    picturePermissionRequired: PropTypes.bool,
  }),
  contact: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    pictureAllowed: PropTypes.bool,
  }),
  zoom: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  changePictureAllowed: PropTypes.func.isRequired,
};

export default CGVStep;
