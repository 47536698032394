import SocketProvider from './SocketProvider';
import useBroadcast from './useBroadcast';
import useSocketListener from './useSocketListener';
// deprecated exports
import connectSocket from './connectSocket'; // @deprecated
import exposeSocketBroadcast from './exposeSocketBroadcast'; // @deprecated
import registerSocketListener from './registerSocketListener'; // @deprecated

const socketConnect = connectSocket(); // @deprecated

export {
  SocketProvider,
  useBroadcast,
  useSocketListener,
  // deprecated exports
  socketConnect,
  connectSocket,
  exposeSocketBroadcast,
  registerSocketListener,
};
