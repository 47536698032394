import { compose, lifecycle, withProps, withHandlers, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { getEntity } from '../../../../selectors/entities';
import { fetchFile, fetchEntity } from '../../../../reducers/entities';
import {
  setAcceptTerms as acceptTermsAction,
  setAcceptMandate as acceptMandateAction,
  setStep as setStepAction,
  changePictureAllowed as changePictureAllowedAction,
} from '../../../../reducers/saleContract';
import { getSaleContract, getNeedMandate, getContactId } from '../../../../selectors/saleContract';
import CGVStep from './CGVStep';

const mapStateToProps = (state, { sale, saleId }) => {
  const contactId = getContactId(state);
  return {
    file: getEntity(state, { collection: 'files', entityId: sale && `${saleId}/contract` }),
    ...getSaleContract(state),
    needMandate: getNeedMandate(state),
    club: getEntity(state, { collection: 'clubs', entityId: sale && sale.clubId }),
    contactId,
    contact: getEntity(state, { collection: 'contacts', entityId: contactId }),
  };
};

const actionCreators = {
  setAcceptTerms: acceptTermsAction,
  setAcceptMandate: acceptMandateAction,
  setStep: setStepAction,
  changePictureAllowed: changePictureAllowedAction,
};

export default compose(
  connect(),
  connect(
    mapStateToProps,
    actionCreators
  ),
  withProps(({ needMandate, acceptMandate, acceptTerms }) => ({
    isButtonActive: acceptTerms && (needMandate ? acceptMandate : true),
  })),
  withStateHandlers(
    {
      zoom: 1,
      isLoading: true,
    },
    {
      setIsLoading: prevState => value => ({
        ...prevState,
        isLoading: value,
      }),
      setZoom: ({ isLoading, ...prevState }) => value => ({
        ...prevState,
        isLoading: true,
        zoom: value,
      }),
    }
  ),
  lifecycle({
    async componentDidMount() {
      const { sale, saleId, dispatch, contactId } = this.props;

      dispatch(fetchFile(`${saleId}/contract`));
      if (this.props.sale) dispatch(fetchEntity(sale.clubId));

      if (this.props.contactId) dispatch(fetchEntity(contactId));
    },
    async componentDidUpdate(prevProps) {
      const { sale, dispatch } = this.props;

      if (!prevProps.sale && sale) {
        dispatch(fetchEntity(sale.clubId));
      }
    },
  }),
  withHandlers({
    onChangeAcceptTerms: ({ setAcceptTerms }) => event => {
      setAcceptTerms(event.target.checked);
    },
    onChangeAcceptMandate: ({ setAcceptMandate }) => event => {
      setAcceptMandate(event.target.checked);
    },
    nextStep: ({ setStep, activeStep }) => () => {
      setStep(activeStep + 1);
    },
  })
)(CGVStep);
