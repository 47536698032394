import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  state: null,
};

// ------------------------------------
// Action creators
// ------------------------------------
export const saveState = state => ({
  type: 'payline/SAVE_STATE',
  value: state,
});

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer(initialState, {
  'payline/SAVE_STATE': (state, { value }) => ({
    state: value,
  }),
  'clientScreen/RESET': () => initialState,
});
