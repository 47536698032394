import React from 'react';
import PropTypes from 'prop-types';
import SaleContractLayout from './SaleContractLayout';
import SignatureStep from './SignatureStep';
import CommunicationStep from './CommunicationStep';
import CGVStep from './CGVStep';

const SaleContract = ({ activeStep, steps, sale, saleId }) => (
  <SaleContractLayout>
    {(() => {
      switch (steps[activeStep]) {
        case 'sale-contract.steps-validate-cgv':
          return <CGVStep saleId={saleId} sale={sale} />;
        case 'sale-contract.steps-choose-communication':
          return <CommunicationStep />;
        case 'sale-contract.steps-sign-form':
          return <SignatureStep />;
        default:
          return null;
      }
    })()}
  </SaleContractLayout>
);

SaleContract.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string),
  saleId: PropTypes.string.isRequired,
  sale: PropTypes.shape({
    '@id': PropTypes.string,
    contractId: PropTypes.string,
  }),
};

export default SaleContract;
