import { createSelector } from '@reduxjs/toolkit';

/**
 * Returns payline state
 *
 * @param {object} state
 * @returns {object}
 */
export const getPayline = state => state.payline;

/**
 * Returns payline state
 *
 * @param {object} state
 * @returns {string}
 */
export const getPaylineState = createSelector([getPayline], payline => payline.state);
