import { useCallback } from 'react';
import { useSocket } from './SocketProvider';

const useBroadcast = () => {
  const socket = useSocket();

  const broadcast = useCallback(
    (...args) => {
      socket.emit('broadcast', args);
    },
    [socket]
  );

  return broadcast;
};

export default useBroadcast;
