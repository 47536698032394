import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LogoLarge from '../../components/LogoLarge';
import LanguageSelector from '../../components/App/LanguageSelector';

const requestFullscreen = () => {
  const el = document.documentElement;
  const rfs =
    el.requestFullscreen ||
    el.webkitRequestFullScreen ||
    el.mozRequestFullScreen ||
    el.msRequestFullscreen;
  rfs && rfs.call(el);
};

const Startup = ({ deviceId, history }) => (
  <Grid container direction="column" style={{ background: '#f7f6f6', height: '100vh' }}>
    <Grid item style={{ background: '#f7f6f6', padding: '20px' }}>
      <LogoLarge dimension={100} />
      <LanguageSelector />
    </Grid>
    <Grid container direction="column" alignItems="center" style={{ flex: 1 }}>
      <Grid container direction="column" style={{ width: '70%', textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom>
          <FormattedMessage id="startup.title" />
        </Typography>
        <code style={{ fontSize: '4rem', padding: '20px' }}>{deviceId}</code>
      </Grid>
      <Grid item style={{ padding: '50px' }}>
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={() => {
            requestFullscreen();
            history.push('/ready');
          }}
        >
          <FormattedMessage id="startup.start-button" />
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

Startup.propTypes = {
  deviceId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default Startup;
