import { createSelector } from '@reduxjs/toolkit';
import { denormalizeEntity } from '../endpoints';
import customThemeConstant from '../constants/customTheme';
import { getEntities } from './entities';

/**
 * Returns config state
 *
 * @param {object} state
 * @returns {object}
 */
export const getCustomThemeState = state => state.customTheme;

/**
 * Returns isLoading
 *
 * @param {object} state
 * @returns {bool}
 */
export const getIsLoading = createSelector(
  [getCustomThemeState],
  customTheme => customTheme.isLoading
);

/**
 * Returns customThemeId
 *
 * @param {object} state
 * @returns {customTheme[]}
 */
export const getCustomThemeId = createSelector(
  [getCustomThemeState],
  customTheme => customTheme.customThemeId
);

/**
 * Returns a list of customThemeIds denormalize
 *
 * @param {Object} state
 * @returns {customTheme[]}
 */
export const getCustomTheme = createSelector(
  [getCustomThemeId, getEntities],
  (customThemeId, entities) => {
    const customThemeEntity = denormalizeEntity(customThemeId, entities) || {};

    // add default values
    const customTheme = {
      ...customThemeEntity,
      properties: {
        primaryColor: '#02b2ca',
        secondaryColor: '#02b2ca',
        ...(customThemeEntity.properties || {}),
      },
    };

    // resolve files
    const updatedProperties = Object.entries(customTheme.properties)
      .filter(([name, property]) => property instanceof Object && property.fileId !== undefined)
      .map(([name, property]) => {
        let file;
        if (property.fileId) {
          try {
            file = denormalizeEntity(
              { id: property.fileId.replace('/files/logos', '/public/logos'), schema: 'File' },
              entities
            );
          } catch {
            // continue
          }
        }
        return [name, file];
      })
      .reduce((properties, [name, fileUrl]) => ({ ...properties, [name]: fileUrl }), {});
    // send final result
    return { ...customTheme, properties: { ...customTheme.properties, ...updatedProperties } };
  }
);

/**
 * Returns PrimaryColor
 *
 * @param {object} state
 * @returns {string}
 */
export const getPrimaryColor = createSelector(
  [getCustomTheme],
  customTheme => customTheme.properties && customTheme.properties.primaryColor
);

/**
 * Returns SecondaryColor
 *
 * @param {object} state
 * @returns {string}
 */
export const getSecondaryColor = createSelector(
  [getCustomTheme],
  customTheme => customTheme.properties && customTheme.properties.secondaryColor
);

/**
 * Returns theme
 *
 * @param {object} state
 * @returns {string}
 */
export const getTheme = createSelector(
  [getPrimaryColor, getSecondaryColor],
  (primary, secondary) => ({
    palette: {
      primary: {
        main: primary !== null ? primary : customThemeConstant.primary,
      },
      secondary: {
        main: secondary !== null ? secondary : customThemeConstant.secondary,
      },
    },
  })
);
