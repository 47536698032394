import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import LogoImg from './resamania2_logo.png';

const LogoLarge = ({ dimension, logo }) => {
  const intl = useIntl();

  return (
    <Grid
      style={{ width: dimension, height: dimension, background: '#fff', borderRadius: '50%' }}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        component={() => (
          <img
            src={logo || LogoImg}
            style={{ width: '60%', height: 'auto' }}
            alt={intl.formatMessage({ id: 'global.logo' })}
          />
        )}
      />
    </Grid>
  );
};

LogoLarge.propTypes = {
  dimension: PropTypes.number.isRequired,
  logo: PropTypes.string,
};

export default LogoLarge;
