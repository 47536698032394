import { createReducer } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import merge from 'deepmerge';
import ENTITY_SCHEMA from '../endpoints';
import sendHTTPRequest from './_sendHTTPRequest';

import { getResolvedUri } from '../selectors/config';

/**
 * @typedef {Object.<string, Collection>} Entities
 * @typedef {Object.<string, Entity>} Collection
 * @typedef {Object} Entity
 */

const initialState = {};

// ------------------------------------
// Actions
// ------------------------------------

export const fetchEntity = (entityIdentifier, extraConfig = {}) => async (dispatch, getState) => {
  const entityUri = entityIdentifier && entityIdentifier.startsWith('_') ? null : entityIdentifier;

  try {
    const response = await dispatch(
      sendHTTPRequest({ url: getResolvedUri(getState(), { uri: entityUri }), ...extraConfig })
    );

    const parsedResult = { response, ...normalize(response.data, ENTITY_SCHEMA) };
    dispatch({ type: 'entities/RECEIVE_FETCH_RESULT', ...parsedResult });

    return parsedResult;
  } catch (error) {
    dispatch({ type: 'entities/HANDLE_FETCH_ERROR', error });
    throw error;
  }
};

export const fetchFile = (fileUri, extraConfig = {}) => async (dispatch, getState) => {
  try {
    const response = await dispatch(
      sendHTTPRequest({
        url: getResolvedUri(getState(), { uri: fileUri }),
        ...extraConfig,
        responseType: 'arraybuffer',
      })
    );

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const parsedResult = {
      '@id': fileUri,
      data: URL.createObjectURL(blob),
    };
    dispatch({ type: 'entities/RECEIVE_FILE_CONTENT', ...parsedResult });

    return parsedResult;
  } catch (error) {
    dispatch({ type: 'entities/HANDLE_FETCH_ERROR', error });
    throw error;
  }
};

export const fetchEntityList = (listIdentifier, extraConfig = {}) => async (dispatch, getState) => {
  try {
    const response = await dispatch(
      sendHTTPRequest({ url: getResolvedUri(getState(), { uri: listIdentifier }), ...extraConfig })
    );

    const parsedResult = { response, ...normalize(response.data['hydra:member'], [ENTITY_SCHEMA]) };
    dispatch({ type: 'entities/RECEIVE_FETCH_RESULT', ...parsedResult });

    return parsedResult;
  } catch (error) {
    dispatch({ type: 'entities/HANDLE_FETCH_ERROR', error });
    throw error;
  }
};

export const saveEntity = (entityIdentifier, extraConfig = {}) => async (dispatch, getState) => {
  const regexp = '/[-a-zA-Z0-9@:%._+~#=]*/[a-zA-z]*/[0-9]*';
  const isEntityId = entityIdentifier.match(regexp);

  try {
    const response = await dispatch(
      sendHTTPRequest({
        method: isEntityId ? 'PUT' : 'POST',
        url: getResolvedUri(getState(), { uri: entityIdentifier }),
        ...extraConfig,
      })
    );

    const parsedResult = { response, ...normalize(response.data, ENTITY_SCHEMA) };
    dispatch({ type: 'entities/RECEIVE_SAVE_RESULT', ...parsedResult });

    return parsedResult;
  } catch (error) {
    dispatch({ type: 'entities/HANDLE_SAVE_ERROR', error });
    throw error;
  }
};

export const applyEntityTransition = (entityIdentifier, transitionName, extraConfig = {}) => async (
  dispatch,
  getState
) => {
  try {
    const response = await dispatch(
      sendHTTPRequest({
        method: 'POST',
        url: getResolvedUri(getState(), { uri: `${entityIdentifier}/transitions` }),
        data: { transition: transitionName },
        ...extraConfig,
      })
    );

    const parsedResult = { response, ...normalize(response.data, ENTITY_SCHEMA) };
    dispatch({ type: 'entities/RECEIVE_TRANSITION_RESULT', ...parsedResult });

    return parsedResult;
  } catch (error) {
    dispatch({ type: 'entities/HANDLE_TRANSITION_ERROR', error });
    throw error;
  }
};

// ------------------------------------
// Handlers
// ------------------------------------

const overwriteMerge = (...args) => args[1];

/**
 * Receive entities after an HTTP request
 *
 * @param {Entities} prevState
 * @param {Entities} action.entities
 * @returns {Entities}
 */
export const handleReceiveEntities = (prevState, { entities }) =>
  merge(prevState, entities, { arrayMerge: overwriteMerge });

export const handleReceiveFile = (prevState, file) =>
  merge(
    prevState,
    {
      files: {
        [file['@id']]: file.data,
      },
    },
    { arrayMerge: overwriteMerge }
  );

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer(initialState, {
  'entities/RECEIVE_FETCH_RESULT': handleReceiveEntities,
  'entities/RECEIVE_TRANSITION_RESULT': handleReceiveEntities,
  'entities/RECEIVE_SAVE_RESULT': handleReceiveEntities,
  'entities/RECEIVE_FILE_CONTENT': handleReceiveFile,
});
