import { useEffect } from 'react';
import { useSocket } from './SocketProvider';

const useSocketListener = (eventName, handler) => {
  const socket = useSocket();

  useEffect(
    () => {
      socket.on(eventName, handler);
      return () => socket.off(eventName, handler);
    },
    [eventName, handler, socket]
  );
};

export default useSocketListener;
