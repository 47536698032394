import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { triggerFetchGrootConfig } from './reducers/config';

const actionCreators = {
  fetchGrootConfig: triggerFetchGrootConfig,
};

const withGrootConfig = WrappedComponent => {
  const WithGroot = ({ fetchGrootConfig, ...props }) => {
    useEffect(() => {
      fetchGrootConfig();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <WrappedComponent {...props} />;
  };

  WithGroot.propTypes = {
    fetchGrootConfig: PropTypes.func.isRequired,
  };

  return connect(null, actionCreators)(WithGroot);
};

export default withGrootConfig;
