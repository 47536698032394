import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Sketchfield from '@stadline/mui-sketch-field';
import { Button, Grid, Typography } from '@mui/material';
import MandateLayout from './MandateLayout';

const MandateScreen = ({ onSketchClear, value, onChange, onConfirm, isWaiting }) => {
  const intl = useIntl();

  return (
    <MandateLayout>
      <Grid container spacing={1} direction="column" style={{ height: '100%' }}>
        <Grid item>
          <Typography variant="h5" align="center" style={{ marginTop: 0 }}>
            <FormattedMessage id="mandate.signature.title" />
          </Typography>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Sketchfield
            value={value}
            placeholder={intl.formatMessage({ id: 'mandate.signature.sketchfield.placeholder' })}
            width="100%"
            height="90%"
            onChange={onChange}
          />
        </Grid>
        <Grid container wrap="wrap" spacing={1} justifyContent="flex-end">
          {value && !isWaiting && (
            <Grid item>
              <Button color="primary" onClick={onSketchClear} variant="outlined">
                <FormattedMessage id="mandate.signature.delete-signature" />
              </Button>
            </Grid>
          )}
          {!isWaiting && (
            <Grid item>
              <Button color="primary" disabled={!value} onClick={onConfirm} variant="contained">
                <FormattedMessage id="mandate.signature.confirm" />
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MandateLayout>
  );
};

MandateScreen.propTypes = {
  onSketchClear: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isWaiting: PropTypes.bool.isRequired,
};

export default MandateScreen;
