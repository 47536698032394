import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import registerSocketListener from '../../socket.io-react/registerSocketListener';
import { change as changeScreen, reset as resetScreen } from '../../reducers/clientScreen';
import { getDeviceId } from '../../selectors/config';
import { getClientScreen } from '../../selectors/clientScreen';
import { getPaylineState } from '../../selectors/payline';
import ClientScreen from './ClientScreen';

const mapStateToProps = state => ({
  deviceId: getDeviceId(state),
  screen: getClientScreen(state),
  paylineState: getPaylineState(state),
});

const handleMessage = ({ dispatch, paylineState }) => message => {
  switch (message.type) {
    case 'sale_contract_signature_enter':
      dispatch(changeScreen('contract-signature', message));
      break;
    case 'mandate_signature_enter':
      dispatch(changeScreen('mandate-signature', message));
      break;
    case 'payline_wallet_enter':
      dispatch(changeScreen('payline_wallet', message));
      break;
    case 'cancellation_certificate_signature_enter':
      dispatch(changeScreen('certificate-signature', message));
      break;
    case 'mandate_signature_exit':
    case 'sale_contract_signature_exit':
    case 'cancellation_certificate_signature_exit':
    case 'sale_stop':
      dispatch(resetScreen());
      break;
    case 'payline_wallet_exit':
      paylineState !== 'PAYMENT_SUCCESS' && dispatch(resetScreen());
      break;
    default:
    // do nothing
  }
};

export default compose(
  // container
  connect(mapStateToProps),
  registerSocketListener(props => `device-${props.deviceId}.message`, handleMessage),
  lifecycle({
    componentWillUnmount() {
      this.props.dispatch(resetScreen());
    },
  })
)(ClientScreen);
