import { compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import exposeSocketBroadcast from '../../../../socket.io-react/exposeSocketBroadcast';
import registerSocketListener from '../../../../socket.io-react/registerSocketListener';
import { getDeviceId } from '../../../../selectors/config';
import { getAuthenticationHeaders } from '../../../../selectors/authentication';
import SignatureStep from './SignatureStep';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const mapStateToProps = state => ({
  deviceId: getDeviceId(state),
  authenticationHeaders: getAuthenticationHeaders(state),
});

const handleMessage = ({ setValue, setIsWaiting }) => message => {
  switch (message.type) {
    case 'sale_contract_signature_reset':
      setValue(null);
      setIsWaiting(false);
      break;
    default:
    // do nothing
  }
};

export default compose(
  withState('isWaiting', 'setIsWaiting', false),
  withState('value', 'setValue', null),
  connect(mapStateToProps),
  exposeSocketBroadcast,
  withHandlers({
    onSketchClear: ({ setValue }) => () => {
      setValue(null);
    },
    onChange: ({ setValue }) => value => {
      setValue(value);
    },
    onConfirm: ({
      broadcast,
      deviceId,
      value,
      authenticationHeaders,
      setIsWaiting,
    }) => async () => {
      setIsWaiting(true);
      broadcast(`device-${deviceId}.message`, {
        type: 'sale_contract_signed',
        payload: value,
        headers: authenticationHeaders,
      });
      await delay(3000);
      setIsWaiting(false);
    },
  }),
  registerSocketListener(props => `device-${props.deviceId}.message`, handleMessage)
)(SignatureStep);
