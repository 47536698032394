import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const SocketContext = createContext();
export const useSocket = () => useContext(SocketContext);

function SocketProvider({ socket, children }) {
  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
}

SocketProvider.propTypes = {
  socket: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  children: PropTypes.node.isRequired,
};

export default SocketProvider;
