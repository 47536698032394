import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getCustomTheme } from '../../selectors/customTheme';
import LogoLarge from './LogoLarge';

const mapStateToProps = state => {
  const theme = getCustomTheme(state);
  const logo = theme && theme.properties && (theme.properties.homeLogo || theme.properties.logo);

  return { logo };
};

export default compose(
  connect(
    mapStateToProps,
    null
  )
)(LogoLarge);
